<template>
  <StMBanners
    class="casino-banners"
    :is-loading="isLoading"
    :items="banners"
    :is-mini="isMini"
    size="l"
  />
</template>

<script setup lang="ts">
import StMBanners from '@st/ui/components/StMBanners/StMBanners.vue'

const { banners, isLoading } = useBanners('mobileCasino')

defineProps<{
  isMini?: boolean
}>()
</script>

<style scoped>
.casino-banners {
  margin-top: var(--spacing-150);
  margin-bottom: var(--spacing-100);
}
</style>
